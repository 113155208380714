<template>
  <div>
    <ValidationObserver v-slot="context">
      <form @submit.prevent="submit(context)">
        <vx-card class="mb-base">
          <FormLayout>
            <FormLabel>
              <span>ชื่อ *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="ชื่อ" 
                rules="required">
                <vs-input 
                  v-model="formData.seo_name" 
                  class="w-full" 
                  name="seo_name" />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>URL *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="URL" 
                rules="required">
                <vs-input 
                  v-model="formData.url" 
                  class="w-full" 
                  name="url" />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>SEO Title *</span>
              <br >
              <span class="text-warning text-sm">กรอกได้ไม่เกิน 160 ตัวอักษร</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="SEO Title"
                rules="required|max:160"
              >
                <vs-input
                  v-model="formData.meta_title"
                  class="w-full"
                  name="meta_title"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>SEO H1</span>
              <br >
              <span class="text-warning text-sm">กรอกได้ไม่เกิน 255 ตัวอักษร</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="SEO Title" 
                rules="max:255">
                <vs-input 
                  v-model="formData.meta_h1" 
                  class="w-full" 
                  name="meta_h1" />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>SEO Description *</span>
              <br >
              <span class="text-warning text-sm">กรอกได้ไม่เกิน 300 ตัวอักษร</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="SEO Description"
                rules="required|max:300"
              >
                <vs-textarea
                  v-model="formData.meta_description"
                  class="w-full"
                  name="meta_description"
                  rows="6"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>SEO Keywords *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="SEO Keywords"
                rules="required"
              >
                <vs-textarea
                  v-model="formData.meta_keyword"
                  class="w-full"
                  name="meta_keyword"
                  rows="4"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>SEO Thumbnail *</span>
              <br >
              <span class="text-warning text-sm">นามสกุล .jpg / .png / .gif</span>
              <br >
              <span class="text-warning text-sm">ขนาดรูปแนะนำ 600 x 338 px, ไม่เกิน 300 KB</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="SEO Thumbnail"
                rules="required|lh_mimes:jpg,png|lh_image_resolution:600,338"
              >
                <ImageUpload
                  :folder="`${baseFolder}/seo/${formData.folder_name}/Thumbnail`"
                  v-model="formData.meta_thumbnail"
                  selection-name="meta_thumbnail"
                  crop="scale"
                  has-delete-button
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>Geo Title</span>
              <br >
              <span class="text-warning text-sm">กรอกได้ไม่เกิน 100 ตัวอักษร</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="Geo Title"
                rules="max:100"
              >
                <vs-textarea
                  v-model="formData.geo_title"
                  class="w-full"
                  name="geo_title"
                  rows="6"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>Geo Detail</span>
              <br >
              <span class="text-warning text-sm">กรอกได้ไม่เกิน 700 ตัวอักษร</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="Geo Detail"
                rules="max:700"
              >
                <vs-textarea
                  v-model="formData.geo_detail"
                  class="w-full"
                  name="geo_detail"
                  rows="6"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
        </vx-card>
        <vx-card class="mb-base">
          <FormLayout>
            <FormLabel>
              <span>แก้ไขล่าสุดโดย</span>
            </FormLabel>
            <div class="flex items-center vx-col w-full md:w-6/12">
              <span class="mt-2"> {{formData.updated_by}}</span>
            </div>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>แก้ไขล่าสุดวันที่</span>
            </FormLabel>
            <div class="flex items-center vx-col w-full md:w-6/12">
              <span class="mt-2"> {{formatDate(formData.updated_at)}}</span>
            </div>
          </FormLayout>
          <FormLayout>
            <FormLabel/>
            <FormContent>
              <vs-button 
                class="w-1/3 mr-4" 
                @click="submit(context)">Submit</vs-button>
            </FormContent>
          </FormLayout>
        </vx-card>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { onMounted, computed } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import Datepicker from 'vuejs-datepicker'
import { quillEditor } from 'vue-quill-editor'
import ImageUpload from '@/components/upload/ImageUpload'
import useDatepicker from '@/use/useDatepicker'
import useSEOUtil from '@/use/useSEOUtil'
import useCrud from '@/use/useCrud'
import useNotify from '@/use/useNotify'
import env from '@/env'
import omit from 'lodash/omit'
import get from 'lodash/get'

export default {
  name: 'SEOForm',
  components: {
    ImageUpload,
    Datepicker,
    ValidationObserver,
    QuillEditor: quillEditor,
  },
  setup(props, ctx) {
    const { success: notifySuccess, error: notifyError } = useNotify(ctx)
    const {
      root: { $store, $router, $vs },
    } = ctx
    const { initialData, formData, fetchByRouteId } = useSEOUtil(ctx)
    const crudForm = useCrud(ctx, 'seo')
    crudForm.routePrefix.value = 'seo'

    onMounted(() => {
      fetchByRouteId()
    })

    const updateItem = ({ validate }) => {
      validate().then((result) => {
        if (!result) {
          notifyError({
            text: 'กรุณาตรวจสอบข้อมูลให้ถูกต้อง'
          })
          return
        }
        $vs.loading()

        const activeUser = ctx.root.$store.state.AppActiveUser
        const email = get(activeUser, 'email', '')
        let created_by = formData.created_by
        const updated_by = email

        // add user created by
        if (!created_by) {
          created_by = email
        }

        $store
          .dispatch('seo/updateItem', { initialData,
            formData: {
              ...omit(formData, ['updated_at']),
              created_by,
              updated_by
            }
          })
          .then(() => {
            $router.push({ name: 'seo' })
            $vs.loading.close()
            notifySuccess({ text: `${formData.id ? 'แก้ไข' : 'เพิ่ม'}ข้อมูลเรียบร้อย` })
          })
          .catch((error) => {
            $vs.loading.close()
            if (error.message.split('unique constraint "seo_url_key"').length > 1) {
              notifyError({
                text:
                  'ไม่สามารถบันทึกข้อมูลได้ เนื่องจาก URL ซ้ำกับข้อมูลที่มีอยู่ในระบบ',
              })
            } else {
              notifyError({ text: JSON.stringify(error) })
            }
          })
      })
    }

    const submit = (ctx) => {
      formData.url = decodeURIComponent(formData.url)
      if (formData.id) {
        updateItem(ctx)
      } else {
        crudForm.addItem(ctx, formData)
      }
    }

    const baseFolder = computed(() => env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER)

    return {
      ...crudForm,
      updateItem,
      formData,
      submit,
      useDatepicker: useDatepicker(),
      baseFolder
    }
  },
}
</script>
