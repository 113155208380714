var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(context){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit(context)}}},[_c('vx-card',{staticClass:"mb-base"},[_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("ชื่อ *")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"ชื่อ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"seo_name"},model:{value:(_vm.formData.seo_name),callback:function ($$v) {_vm.$set(_vm.formData, "seo_name", $$v)},expression:"formData.seo_name"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("URL *")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"URL","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"url"},model:{value:(_vm.formData.url),callback:function ($$v) {_vm.$set(_vm.formData, "url", $$v)},expression:"formData.url"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("SEO Title *")]),_c('br'),_c('span',{staticClass:"text-warning text-sm"},[_vm._v("กรอกได้ไม่เกิน 160 ตัวอักษร")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"SEO Title","rules":"required|max:160"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"meta_title"},model:{value:(_vm.formData.meta_title),callback:function ($$v) {_vm.$set(_vm.formData, "meta_title", $$v)},expression:"formData.meta_title"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("SEO H1")]),_c('br'),_c('span',{staticClass:"text-warning text-sm"},[_vm._v("กรอกได้ไม่เกิน 255 ตัวอักษร")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"SEO Title","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"meta_h1"},model:{value:(_vm.formData.meta_h1),callback:function ($$v) {_vm.$set(_vm.formData, "meta_h1", $$v)},expression:"formData.meta_h1"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("SEO Description *")]),_c('br'),_c('span',{staticClass:"text-warning text-sm"},[_vm._v("กรอกได้ไม่เกิน 300 ตัวอักษร")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"SEO Description","rules":"required|max:300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-textarea',{staticClass:"w-full",attrs:{"name":"meta_description","rows":"6"},model:{value:(_vm.formData.meta_description),callback:function ($$v) {_vm.$set(_vm.formData, "meta_description", $$v)},expression:"formData.meta_description"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("SEO Keywords *")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"SEO Keywords","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-textarea',{staticClass:"w-full",attrs:{"name":"meta_keyword","rows":"4"},model:{value:(_vm.formData.meta_keyword),callback:function ($$v) {_vm.$set(_vm.formData, "meta_keyword", $$v)},expression:"formData.meta_keyword"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("SEO Thumbnail *")]),_c('br'),_c('span',{staticClass:"text-warning text-sm"},[_vm._v("นามสกุล .jpg / .png / .gif")]),_c('br'),_c('span',{staticClass:"text-warning text-sm"},[_vm._v("ขนาดรูปแนะนำ 600 x 338 px, ไม่เกิน 300 KB")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"SEO Thumbnail","rules":"required|lh_mimes:jpg,png|lh_image_resolution:600,338"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ImageUpload',{attrs:{"folder":(_vm.baseFolder + "/seo/" + (_vm.formData.folder_name) + "/Thumbnail"),"selection-name":"meta_thumbnail","crop":"scale","has-delete-button":""},model:{value:(_vm.formData.meta_thumbnail),callback:function ($$v) {_vm.$set(_vm.formData, "meta_thumbnail", $$v)},expression:"formData.meta_thumbnail"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("Geo Title")]),_c('br'),_c('span',{staticClass:"text-warning text-sm"},[_vm._v("กรอกได้ไม่เกิน 100 ตัวอักษร")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"Geo Title","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-textarea',{staticClass:"w-full",attrs:{"name":"geo_title","rows":"6"},model:{value:(_vm.formData.geo_title),callback:function ($$v) {_vm.$set(_vm.formData, "geo_title", $$v)},expression:"formData.geo_title"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("Geo Detail")]),_c('br'),_c('span',{staticClass:"text-warning text-sm"},[_vm._v("กรอกได้ไม่เกิน 700 ตัวอักษร")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"Geo Detail","rules":"max:700"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-textarea',{staticClass:"w-full",attrs:{"name":"geo_detail","rows":"6"},model:{value:(_vm.formData.geo_detail),callback:function ($$v) {_vm.$set(_vm.formData, "geo_detail", $$v)},expression:"formData.geo_detail"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1)],1),_c('vx-card',{staticClass:"mb-base"},[_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("แก้ไขล่าสุดโดย")])]),_c('div',{staticClass:"flex items-center vx-col w-full md:w-6/12"},[_c('span',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.formData.updated_by))])])],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("แก้ไขล่าสุดวันที่")])]),_c('div',{staticClass:"flex items-center vx-col w-full md:w-6/12"},[_c('span',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.formData.updated_at)))])])],1),_c('FormLayout',[_c('FormLabel'),_c('FormContent',[_c('vs-button',{staticClass:"w-1/3 mr-4",on:{"click":function($event){return _vm.submit(context)}}},[_vm._v("Submit")])],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }